@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(50px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(50px);
  }
}

@keyframes bounceInTop {
  0% {
    transform: translateY(-50px);
    animation-timing-function: ease;
  }
  38% {
    transform: translateY(0);
    animation-timing-function: ease;
  }
  55% {
    transform: translateY(-0.5px);
    animation-timing-function: ease;
  }
  100% {
    transform: translateY(0);
    animation-timing-function: ease;
  }
}

@keyframes detach {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.8;
  }
  75% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
  transition-timing-function: ease;
  animation-fill-mode: forwards;
}

.fadeOut {
  animation-name: fadeOut;
  transition-timing-function: ease;
  animation-fill-mode: forwards;
}

.slideUp {
  animation-name: slideUp;
  transition-timing-function: ease;
  animation-fill-mode: forwards;
}

.slideDown {
  animation-name: slideDown;
  transition-timing-function: ease;
  animation-fill-mode: forwards;
}

.bounceInTop {
  animation: bounceInTop 1.5s both;
}

.detach {
  animation-name: detach;
  transition-timing-function: ease;
  animation-fill-mode: forwards;
}

.flash {
  animation-name: flash;
  transition-timing-function: ease;
  animation-iteration-count: infinite;
  animation-duration: 2s;
}
