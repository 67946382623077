html,
body {
  color: #191f2c;
}

::placeholder {
  color: #a0afc0;
}

* {
  box-sizing: border-box;
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0px;
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: NeueHansKendrick;
  font-weight: 400;
  src: url('../../node_modules/@mapped/rivet/assets/fonts/NeueHansKendrick-Regular.woff')
    format('woff');
}

@font-face {
  font-family: NeueHansKendrick;
  font-weight: 500;
  src: url('../../node_modules/@mapped/rivet/assets/fonts/NeueHansKendrick-Medium.woff')
    format('woff');
}

@font-face {
  font-family: NeueHansKendrick;
  font-weight: 700;
  src: url('../../node_modules/@mapped/rivet/assets/fonts/NeueHansKendrick-Bold.woff')
    format('woff');
}

@import './fixes.css';
@import './nprogress.css';
@import './animations.css';
